div:not(.except, #my-tooltip) {
  transition: all ease-in-out 0.4s !important;
}

html {
  scroll-behavior: smooth !important;
}

.widthAnimation {
  animation-name: expandWidth;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes expandWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.floatingAnimation {
  animation-name: floating;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes floating {
  0% {
    scale: 1.0;
  }  
  50% {
    scale: 1.05;
  }
  100% {
    scale: 1.0;
  }
}

.floating-gradient {
  animation-name: floati;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  background: radial-gradient(circle 14px at 0% 50%, #ffffffd2, #ffffff00);
}

@keyframes floati {
  from { /* radial-gradient(farthest-corner at top right, ..) */
    background-position:left top;
    background-size:200% 100%;
  
  }
  49.9% {
    background-position:left top;  
  }
  50% { /* radial-gradient(farthest-corner at top center, ..) */
    background-size:100% 100%;
  }
  50.1% {
    background-position:right top; 
  }
  to { /* radial-gradient(farthest-corner at top left, ..) */
    background-position:right top;
    background-size:200% 100%;
  }
  /* 0% {
    background: radial-gradient(circle 14px at 0% 50%, #ffffffd2, #ffffff00);
  }
  50% {
    background: radial-gradient(circle 14px at 100% 50%, #ffffffd2, #ffffff00);
  }
  100% {
    background: radial-gradient(circle 14px at 0% 50%, #ffffffd2, #ffffff00);
  } */
}

